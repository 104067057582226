import moment from "moment";

const loanSummaryKeys = [
  "TotalAidPackage",
  "TotalAmountGraduation",
  "TotalFutureLoanValue",
  "TotalMonthlyPayment",
  "NeededSalaryPerYear",
  "NeededPerTerm",
  "TermsPerYear",
  "WeeksPerTerm",
  "ProgramLengthYears",
  "ProgramTermsComplete",
  "TotalCurrentGrantScholarships",
  "TuitionPerTerm",
  "HousingPerTerm",
  "MealsPerTerm",
  "BooksPerTerm",
  "TransportationPerTerm",
  "OtherPerTerm",
  "Savings",
  "IncomePerTerm",
  "ParentsPerTerm",
  "FinancialAid",
  "Wages",
];

export const differenceInMonths = (graduationDate) => {
  const difference =
    moment(new Date()).diff(moment(graduationDate), "months", false) - 6;
  return difference > 0 ? difference : 0;
};

export const calculateMonthly = (principal, interest, payments) => {
  const monthlyInterest = interest / 12;
  const x = Math.pow(1 + monthlyInterest, payments);
  return (principal * x * monthlyInterest) / (x - 1);
};

export const calculateTotalPayment = (principal, interest, payments) => {
  return calculateMonthly(principal, interest, payments) * payments;
};

export const calculateAccruedInterest = ({ interest, balance }) =>
  (interest / 365) * balance * (365 / 12);

export const calculateTotalsWithAdditionalPayments = (
  totalPrincipal,
  weightedInterest,
  monthlyPayment,
  breakPayment,
  increase,
  extended
) => {
  let years = 0;
  const payments = [];
  let accumulatedInterest = (weightedInterest / 12) * totalPrincipal;
  totalPrincipal += accumulatedInterest;
  while (totalPrincipal > 0) {
    let yearlyAccruedInterest = 0;
    let amountOwedForInterest = totalPrincipal;
    const accruedInterest = calculateAccruedInterest({
      interest: weightedInterest,
      balance: amountOwedForInterest,
    });
    for (let i = 0; i < 12; i++) {
      if (totalPrincipal < monthlyPayment) {
        payments.push(totalPrincipal);
        totalPrincipal = 0;
        break;
      } else {
        payments.push(monthlyPayment);
      }
      totalPrincipal -= monthlyPayment;
      yearlyAccruedInterest += accruedInterest - monthlyPayment;
      if (totalPrincipal <= 0) {
        break;
      }
      const interest = (weightedInterest / 12) * totalPrincipal;
      if (monthlyPayment >= accruedInterest) {
        accumulatedInterest += interest;
        totalPrincipal += interest;
      } else {
        accumulatedInterest += accruedInterest;
      }
    }
    years++;
    totalPrincipal +=
      monthlyPayment < accruedInterest ? yearlyAccruedInterest : 0;
    if (increase) {
      monthlyPayment *= 1.02;
    }
    if (years === 2 && extended) {
      years = 0;
      monthlyPayment *= 1.0955;
    }
    if (payments.length >= breakPayment) break;
  }
  return {
    forgiven: totalPrincipal > 0 ? totalPrincipal : 0,
    accumulatedInterest,
    repaymentTotal: payments.reduce((acc, curr) => acc + curr, 0),
    repaymentMonths: payments.length,
  };
};

export const calculateMonthlyPayment = (paybackWithInterest, yearsPayback) =>
  paybackWithInterest / (yearsPayback * 12);

export const calculateNeededYearlySalary = (monthlyPayment) => {
  // Using graduated income contribution rate schedule
  var yearlyPayment = monthlyPayment * 12;

  if (monthlyPayment < 416.67) {
    return yearlyPayment / 0.1;
  }

  if (monthlyPayment < 700) {
    return yearlyPayment / 0.12;
  }

  if (monthlyPayment < 1166.67) {
    return yearlyPayment / 0.14;
  }

  return yearlyPayment / 0.18;
};

export const calculateAdditionalPerTerm = (percentageTotal, totalAid) =>
  totalAid * percentageTotal;

export const calculateDebtByGraduation = (
  termsRemaining,
  amount,
  amountPerTerm
) => amount + termsRemaining * amountPerTerm;

export const calculatePaybackWithInterest = (
  rate,
  totalGraduation,
  yearsPayback,
  interestCompoundedPerYear = 365
) => {
  //rate totalGraduation yearspayback

  var paybackWithInterest;

  console.log("payback rate = ", rate);
  console.log("total graduation =", totalGraduation);

  //standard compound interest forumla
  var top = 1 + rate / 100 / interestCompoundedPerYear;
  var bottom = interestCompoundedPerYear * yearsPayback;
  var pow = Math.pow(top, bottom);
  paybackWithInterest =
    totalGraduation *
    Math.pow(
      1 + rate / interestCompoundedPerYear,
      interestCompoundedPerYear * yearsPayback
    );
  console.log("paybackWithInterest = ", paybackWithInterest);

  return Number(paybackWithInterest);
};

export const calculateMonthlyPaymentAllLoans = (loans) => {
  var monthly = 0;

  for (var l = 0; l < loans.length; l++) {
    loans[l].MonthlyPayment = calculateMonthlyPayment(
      loans[l].FutureValueOfLoan,
      loans[l].YearsPayback
    );
    monthly = monthly + loans[l].MonthlyPayment;
  }
  return monthly;
};

export const calculateAdditioanlPerTermAllLoans = (need, loans) => {
  var additional = 0;

  for (var l = 0; l < loans.length; l++) {
    loans[l].AdditionalPerTerm = calculateAdditionalPerTerm(
      need,
      loans[l].PercentageOfTotalLoans
    );
    additional = additional + loans[l].AdditionalPerTerm;
  }
  return additional;
};

export const calculateDebtByGraduationAllLoans = (termsRemaining, loans) => {
  var debtByGraduation = 0;

  for (var l = 0; l < loans.length; l++) {
    loans[l].TotalGraduation = calculateDebtByGraduation(
      termsRemaining,
      loans[l].Amount,
      loans[l].AdditionalPerTerm
    );
    debtByGraduation = debtByGraduation + loans[l].TotalGraduation;
  }
  return debtByGraduation;
};

export const calculatePaybackWithInterestAllLoans = (
  loans,
  reduceBy = 0,
  simulate
) => {
  var payback = 0;

  for (var l = 0; l < loans.length; l++) {
    let amount = loans[l].Amount || 0;
    if (amount > reduceBy) {
      amount -= reduceBy;
      reduceBy = 0;
    } else {
      reduceBy -= amount;
      amount = 0;
    }
    payback += calculatePaybackWithInterest(
      loans[l].Rate,
      amount,
      simulate || loans[l].YearsPayback
    );
  }
  return payback;
};

export const currencyDisplay = (amount, round = true) => {
  //console.log("amount = ", amount);
  if (typeof amount === "string") return amount;
  if (amount === null || amount === undefined) return "--";
  let amt = round
    ? Number(Number(amount).toFixed(0))
    : Number(Number(amount).toFixed(2));
  if (Number.isNaN(amt)) return "";
  if (amt === -0) amt = 0;
  return amt
    .toLocaleString("en-US", { style: "currency", currency: "USD" })
    .replace(/\.00/g, "");
};

export const interestRateDisplay = (rate) => {
  return `${(rate * 100).toFixed(2)}%`;
};

export const createLoanSummary = (user, loans) => {
  const loanSummary = {};
  for (const key of loanSummaryKeys) {
    loanSummary[key] = Number(user[key]);
  }
  loanSummary.TotalFutureLoanValue = loans.reduce(
    (acc, curr) => acc + curr.FutureValueOfLoan,
    0
  );
  loanSummary.TotalAmountGraduation = calculateDebtByGraduationAllLoans(
    4,
    loans
  );
  loanSummary.TermsRemaining =
    user.ProgramLengthYears * user.TermsPerYear - user.ProgramTermsComplete - 1;

  return loanSummary;
};

export const calculateNeed = (loanSummary) => {
  let need = 0;
  need =
    Number(loanSummary.TuitionPerTerm) +
    Number(loanSummary.HousingPerTerm) +
    Number(loanSummary.MealsPerTerm) +
    Number(loanSummary.BooksPerTerm) +
    Number(loanSummary.TransportationPerTerm) +
    Number(loanSummary.OtherPerTerm);

  console.log("need =", need);

  return need;
};

export const calculateIncome = (loanSummary, wageFreqency) => {
  const income =
    loanSummary.ParentsPerTerm +
    loanSummary.FinancialAid +
    (loanSummary.Savings * 0.8) / 6 +
    loanSummary.Wages * (wageFreqency / 4) * 15 * 0.8;

  console.log("income = ", income);
  return income;
};

export const caclulateReduceBy = (income, need, grants, totalAid) => {
  const totalGrantAmount = grants.reduce(
    (acc, curr) => acc + curr.DisbursedAmount || 0,
    0
  );
  const reduceBy = income - (need - totalAid / 2 - totalGrantAmount);
  console.log(income, need, totalGrantAmount, totalAid / 2, "REDUCE BY PARTS");
  console.log("reduceby = ", reduceBy);
  return reduceBy;
};

export const calculateMonthlyAccruedInterest = (principal, rate) =>
  Math.round((principal * rate) / 12);

export const calculatePrincipal = (monthly, interest, payments) => {
  const monthlyInterest = interest / 12;
  const x = Math.pow(1 + monthlyInterest, payments);
  return Math.round((monthly * (x - 1)) / (x * monthlyInterest));
};

const povertyLevels = {
  "1": 14580,
  "2": 19720,
  "3": 24860,
  "4": 30000,
  "5": 35140,
  "6": 40280,
  "7": 45420,
  "8": 50560,
};

const calculateDiscretionaryIncome = ({ household, income, multiplier }) => {
  return income - povertyLevels[household] * multiplier;
};

export const calculateSAVEPayment = (familySize, income) => {
  const discretionaryIncome = calculateDiscretionaryIncome({
    income,
    household: familySize,
    multiplier: 2.25,
  });

  console.log("discretionary income = ", discretionaryIncome);
  const savePayment = (0.075 * discretionaryIncome) / 12;
  return savePayment < 0 ? 0 : savePayment;
};
