import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@chakra-ui/core';

interface InnerHTMLBoxProps {
  theme: { colors: { link: string } };
}

const InnerHTMLBoxStyled = styled(Box)<InnerHTMLBoxProps>`
  p {
    margin-bottom: 1rem;
    font-weight: normal;
  }
  a {
    color: ${(props) => props.theme.colors.link};
  }
  h3 {
    font-size: 1.28rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }
`;

const InnerHTMLBox = (props: any) => <InnerHTMLBoxStyled {...props} />;

export default InnerHTMLBox;
