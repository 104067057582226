import React from 'react';
import { Box } from '@chakra-ui/core';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

const Overlay = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(251, 251, 254, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
  h2 {
    font-weight: normal;
    margin-top: 4rem;
  }
  p {
    width: 400px;
    text-align: center;
    line-height: 1.44;
    margin-top: 0;
    max-width: 80%;
  }
`;

const scroll = keyframes`
  50%{background-size:80%}
  100%{background-position:125% 0;}
`;

const LineLoader = styled(Box)<{
  theme: { colors: { indigo: string } };
}>`
  width: 200px;
  height: 4px;
  background: ${(props) =>
    `linear-gradient(to right, ${props.theme.colors.indigo}, ${props.theme.colors.indigo})`};
  background-color: #ccc;
  border-radius: 4px;
  background-size: 20%;
  background-repeat: repeat-y;
  background-position: -25% 0;
  animation: ${scroll} 1.2s ease-in-out infinite;
`;

interface LoaderProps {
  header?: string;
  message?: string;
  loadMessage?: string;
}

const Loader = ({ message, loadMessage, header }: LoaderProps) => (
  <Overlay>
    <LineLoader />
    <h2>{header}</h2>
    <p>{loadMessage || message || 'Loading...'}</p>
  </Overlay>
);

export default Loader;
