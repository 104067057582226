import axios from "axios";

interface RVOProps {
  firstName: string;
  lastName: string;
  email: string;
  debt: number;
  income: number;
  affordability: number;
  increasedAffordability?: number;
  mcUsername?: string;
  mcApiKey?: string;
  media?: boolean;
  phone: string;
  term: number;
  percentageDown: number;
}

export const createRVO = (props: RVOProps) =>
  axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/mortgage-coach`, props);
