import { theme } from "@chakra-ui/core";

export default {
  ...theme,
  colors: {
    ...theme.colors,
    indigo: "#2e49ec",
    indigo70: "#2e49ecb3",
    link: "#4183c4",
    darkgray: "#5b6179",
    brightred: "#ff4013",
    lighterblue: "#96a4f5",
    greyblue: "#f6f7ff",
    lightgreen: "#ecfff3",
    standardgreen: "#00963d",
    blueishgrey: "#d5d8e8",
    lightgrey: "#f2f2f2",
    lightergrey: "#a9a9a9",
    irondarkgrey: "#25293b",
    bordergreen: "#76f9ab",
    standardpink: "#ffeded",
    borderpink: "#ff9f9f",
    brand: {
      50: "#e5ebff",
      100: "#b7c2fe",
      200: "#8999f6",
      300: "#5a71f1",
      400: "#2e49ec",
      500: "#132ed2",
      600: "#0c24a5",
      700: "#071a77",
      800: "#010e4a",
      900: "#00041e",
    },
  },
  fonts: {
    ...theme.fonts,
    body: "proxima-nova, sans-serif",
    heading: "proxima-nova, sans-serif",
  },
};
