import React, { FunctionComponent, ReactChild } from "react";
import { Box, Flex } from "@chakra-ui/core";
import styled from "@emotion/styled";

interface PageProps {
  containerHeight?: string;
  containerWidth?: string;
  align?: string;
  stepper?: string[];
  currentStepOverride?: number;
  progressBar?: boolean;
  sideBar?: boolean;
  sideBarContent?: ReactChild;
  progressText?: string;
  sideBarLower?: boolean;
  sideBarLowerContent?: ReactChild;
  sidebarColor?: string;
  containerColor?: string;
  children?: ReactChild;
  theme?: { colors: { indigo: string } };
}

const PageContainer = styled.main`
  background-color: #efefef;
  min-height: calc(100vh - 80px);
  box-sizing: border-box;
  @media (max-width: 480px) {
    padding-top: 1rem;
  }
`;

const NavContainer = styled(Box)`
  width: 250px;
  height: 100%;
  box-shadow: 4px 5px 2px 0px rgba(0, 0, 0, 0.08);
  @media (max-width: 750px) {
    margin-right: 0;
    margin-top: 20px;
    display: none;
  }
`;
const Container = styled.div<PageProps>`
  max-width: calc(100% - 250px);
  background-color: ${(props) =>
    props.containerColor ? props.containerColor : "white"};
  height: auto;
  box-shadow: 4px 5px 2px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
  min-height: ${(props) =>
    props.containerHeight ? props.containerHeight : "65vh"};
  @media (max-width: 750px) {
    max-width: 100%;
  }
`;

const PageNext: FunctionComponent<PageProps> = ({
  children,
  containerWidth,
  containerHeight,
  sideBar,
  sideBarContent,
  sidebarColor = "#061238",
  containerColor,
}) => {
  return (
    <PageContainer>
      <Flex justifyContent="center">
        <Flex flexDirection="column">
          {sideBar && (
            <NavContainer backgroundColor={sidebarColor}>
              {sideBarContent}
            </NavContainer>
          )}
        </Flex>

        <Container
          containerWidth={containerWidth}
          containerHeight={containerHeight}
          containerColor={containerColor}
        >
          {children}
        </Container>
      </Flex>
    </PageContainer>
  );
};

export default PageNext;
