import React from "react";
import Widget from "./pages/Widget";
import { AppProps } from "./types";

const App = ({
  removeHeader,
  apiKey,
  partnerCode,
  primaryColor = "indigo",
  background = "#efefef",
  showBanner = false,
  lenderVersion,
  nonMc,
  organization,
  lenderEmail,
  mcApiKey,
  mcUsername,
  media,
  collectEmail,
  prefilledData,
  nmls,
  lenderPhone,
  displayName,
  website,
  organizationWide,
  showEhl,
}: AppProps) => {
  return (
    <Widget
      showEhl={showEhl}
      website={website}
      nonMc={nonMc}
      lenderVersion={lenderVersion}
      showBanner={showBanner}
      background={background}
      removeHeader={removeHeader}
      apiKey={apiKey}
      primaryColor={primaryColor}
      mcUsername={mcUsername}
      mcApiKey={mcApiKey}
      media={media}
      collectEmail={collectEmail}
      partnerCode={partnerCode}
      prefilledData={prefilledData}
      lenderPhone={lenderPhone}
      nmls={nmls}
      lenderEmail={lenderEmail}
      organization={organization}
      displayName={displayName}
      organizationWide={organizationWide}
    />
  );
};

export default App;
