export const buildWidgetCode = ({
  partnerCode,
  apiKey,
  organization,
  lenderEmail,
  nmls,
  lenderPhone,
}) =>
  `<div id="loansense-widget"></div>
<script>(function (w, d) {
    if (w.LoanSenseWidget) { console.error('LoanSenseWidget already included'); return; }
    var elt = d.createElement('script'); elt.type = "text/javascript"; elt.defer = true;
    elt.addEventListener('load', load);
    elt.src = "https://tools.myloansense.com/index.js";
    var b = d.getElementsByTagName('script')[0];
    b.parentNode.insertBefore(elt, b);
    function load() { 
      w.LoanSenseWidget.init("${apiKey}", { 
      partnerCode: "${partnerCode}", 
      ${organization ? `organization: "${organization}",` : ""} 
      lenderEmail: "${lenderEmail}",
      nmls: "${nmls}",
      ${lenderPhone ? `lenderPhone: "${lenderPhone}"` : ""}
    }); 
      elt.removeEventListener('load', load); 
    }
  })(window, document);
</script>`;
