import axios from "axios";

interface InviteProps {
  email: string;
  lenderEmail: string;
  isSubsidized: boolean;
  phone: string;
  firstName: string;
  lastName: string;
}

export const createLenderInvite = (props: InviteProps) =>
  axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/lender-invite/create`,
    props,
    { headers: { "x-api-key": process.env.REACT_APP_WIDGET_API_KEY } }
  );
