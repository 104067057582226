// @ts-nocheck
import React, { FunctionComponent } from "react";
import { ThemeProvider } from "@chakra-ui/core";
import customTheme from "../theme";

const ChakraThemeProvider: FunctionComponent = ({ children }) => (
  <ThemeProvider theme={customTheme}>{children}</ThemeProvider>
);

export default ChakraThemeProvider;
