import React from 'react';
import {
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Text,
  IconProps,
  PopoverProps,
} from '@chakra-ui/core';
import styled from '@emotion/styled';

interface HintProps {
  iconName: IconProps['name'];
  direction: PopoverProps['placement'];
  message: string;
  color?: string;
  margin?: string;
  position?: IconProps['position'];
  right?: string;
}

const PopUp = styled(PopoverContent)<HintProps>`
  background-color: ${(props) =>
    props.color === '#2e49ec' ? '#e4e8ff' : '#ebeef5'};
  color: #484f6f;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 20px;
  width: 400px;
  z-index: 4;
  border: 0;
  box-shadow: 0px 4px 15px rgba(72, 79, 111, 0.5);
  border-radius: 5px;
  .boldText {
    color: ${(props) => props.color};
    font-weight: 700;
  }
`;

const PopUpText = styled(Text)`
  text-align: left;
`;

const PopUpIcon = styled(Icon)`
  color: #989ea2;
`;

const Hint = ({
  iconName,
  message,
  margin,
  direction,
  color = '#2e49ec',
  position,
  right,
}: HintProps) => (
  <Popover trigger="hover" placement={direction}>
    <PopoverTrigger>
      <PopUpIcon
        right={right}
        position={position}
        margin={margin}
        name={iconName}
      />
    </PopoverTrigger>
    <PopUp
      iconName={iconName}
      message={message}
      direction={direction}
      color={color}
    >
      <PopUpText
        whiteSpace="normal"
        dangerouslySetInnerHTML={{ __html: message }}
      ></PopUpText>
    </PopUp>
  </Popover>
);

export default Hint;
