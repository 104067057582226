import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@loansense/platform";
import { CSSReset } from "@chakra-ui/core";
import { initialize } from "./utils/sendin-blue";
import App from "./App";
import { InitProps } from "./types";

initialize();

export const init = (
  apiKey,
  {
    removeHeader,
    partnerCode,
    primaryColor,
    background,
    showBanner,
    organization,
    lenderVersion,
    lenderEmail,
    mcApiKey,
    mcUsername,
    media,
    collectEmail,
    prefilledData,
    nonMc,
    nmls,
    lenderPhone,
    displayName,
    organizationWide,
    website,
    showEhl,
  }: InitProps = {}
) =>
  ReactDOM.render(
    <ThemeProvider>
      <CSSReset />
      <App
        showEhl={showEhl}
        apiKey={apiKey}
        removeHeader={removeHeader}
        partnerCode={partnerCode}
        primaryColor={primaryColor}
        background={background}
        showBanner={showBanner}
        lenderVersion={lenderVersion}
        lenderEmail={lenderEmail}
        organization={organization}
        mcApiKey={mcApiKey}
        mcUsername={mcUsername}
        media={media}
        collectEmail={collectEmail}
        prefilledData={prefilledData}
        nonMc={nonMc}
        lenderPhone={lenderPhone}
        nmls={nmls}
        displayName={displayName}
        organizationWide={organizationWide}
        website={website}
      />
    </ThemeProvider>,
    document.getElementById("loansense-widget")
  );
